import StatusCode from "../util/StatusCode"
import { CoreAppToken } from "./CoreAppToken"

// --- Enums ---
export enum ModuleType {
    Undefined = 0,
    ImportModule = 1,
    ExportModule = 2,
}

export enum ModuleInstanceStatus {
    Undefined = 0,
    Started = 1,
    Completed = 2,
    FinishedWithError = 3,
    FinishedInQuarantine = 4,
    Cancelled = 5,
}

export enum CoreUserRoleType {
    Unknown = 0,
    Student = 1,
    Duty = 2,
    UserRelation = 3,
}

export enum CoreTriggerType {
    Undefined = 0,
    CronSchedule = 1,
    RunAfterModule = 2
}

export enum AppTokenType {
    UNDEFINED = 0,
    MICROSOFT_APPLICATION = 1,
    MICROSOFT_DELEGATED = 2,
    GOOGLE_SERVICE_ACCOUNT = 3
}

export enum CoreModuleIconState {
    NONE = 0,
    OK = 1,
    WARNING = 2,
    ERROR = 3,
    CRITICAL = 4,
}

export enum CoreSelectableTriggerState {
    UNDEFINED = 0,
    SELECTABLE = 1,
    UNSELECTABLE_SELF = 2,
    Unselectable_LOOP = 3,
}

export enum FlowSyncProduct {
    UNDEFINED = 0,
    SCHOOL_ADMIN = 1,
    SCHEDULE_SYNC = 2,
}

/// --- Interfaces ---
export interface CoreModule {
    id: string
    active: boolean
    dryRun: boolean
    moduleTypeName: string
    moduleTypeDisplayName: string
    title: string
    nextScheduledStart?: string
    status?: CoreModuleStatus
    moduleTriggers?: CoreModuleTrigger[]
    moduleType: ModuleType
    appToken?: CoreAppToken
    runnable: boolean
    dryRunEnabled: boolean
    fileDownloadEnabled: boolean
    selectSchoolUnits: boolean
    sourceEnvironment: string
    product?: FlowSyncProduct
}

export interface CoreModuleStatus {
    id: string
    isStartRequested: boolean
    startedAt?: string
    completedAt?: string
    isInQuarantine: boolean
    isActive: boolean
    title?: string
    runnable: boolean
    fileDownloadEnabled: boolean
    selectSchoolUnits: boolean
    iconState: CoreModuleIconState
    runningTrailingModule?: CoreModuleRef
    status: ModuleInstanceStatus
}

export interface CoreModuleRef {
    id: string
    title?: string
}

export interface CoreModuleTrigger {
    id: string
    triggerType: number
    cronSchedule?: string
    runAfterModuleId?: string
    runAfterModuleTitle?: string
}

export interface CoreModuleInstanceListing {
    id: string
    moduleId: string
    startedAt: string
    completedAt?: string
    isFinishedWithError: boolean
    isFinishedInQuarantine: boolean
    countWarning: number
    countError: number
    countCritical: number
}

export interface CoreModuleInstance {
    moduleInstanceId: string
    moduleId: string
    title: string
    startedAt: string
    completedAt: string
}

export interface CoreModuleInstancePhase {
    id: string
    moduleInstanceId: string
    phaseName: string
    startedAt: string
    completedAt?: string
    status: ModuleInstanceStatus
    moduleInstancePhaseStats: CoreModuleInstancePhaseStat[]
}

export interface CoreModuleInstancePhaseStat {
    id: string
    moduleInstancePhaseId: string
    objectType: string
    total: number
    handled: number
    failed: number
    analyzed?: number
    created?: number
    updated?: number
    inactivated?: number
    archived?: number
    deleted?: number
}

export interface CoreModuleTriggerOverviewModule {
    id: string,
    title: string,
    active: boolean,
    moduleType: ModuleType
}

export interface CoreModuleTriggerOverviewTrigger {
    id: string,
    moduleId: string,
    cronSchedule: string,
    runAfterModuleId: string,
    triggerType: CoreTriggerType,
    nextScheduledStart: string
}

export interface CoreModuleTriggerOverview {
    modules: CoreModuleTriggerOverviewModule[],
    triggers: CoreModuleTriggerOverviewTrigger[],
}

export interface CoreSelectableTriggerModule {
    id: string
    moduleTypeName?: string
    title?: string
    selectable: CoreSelectableTriggerState
}

export interface CoreModuleInstanceLogEntry {
    eventId: number
    severity: number
    message?: string
    eventName?: string
    objectId?: string
    objectSource?: string
    objectType?: string
    timestamp?: string
    module?: CoreModuleRef
}

export interface CoreMyModuleTypes {
    schoolAdmin: boolean
    scheduleSync: boolean
    oneRoster: boolean
}

export interface RoleFilter {
    id: string
    type: string
    displayName: string
}

export interface Result<T> {
    code: StatusCode
    data: T
    error: Error
}

export interface PaginationResult<T> extends Result<T> {
    pagination: {
        totalPages: number
        currentPage: number
        pageSize: number
    }
    totalCount?: number
}

export interface NoContentResult {
    code: StatusCode
    error: Error
}

export interface CoreEnumeration {
    id: number
    name: string
}

export interface CoreDisplayableRef {
    id: string
    title?: string
    active: boolean
}

export interface PageResult<T> {
    totalCount: number
    currentPage: number
    totalPages: number
    pageSize: number
    values: T[]
}

export interface CoreFilter {
    title: string
}

export interface CoreSyllabusFilter {
    id: string
    subjectName?: string
    subjectDesignation?: string
    courseCode?: string,
    courseName?: string,
    startSchoolYear?: number,
    endSchoolYear?: number
}

export interface CoreStopPeriod {
    id: string
    moduleId: string
    reason: string
    startDayOfMonth: number
    startMonth: number
    stopDayOfMonth: number
    stopMonth: number
}

export interface FormCoreStopPeriod {
    id?: string
    moduleId?: string
    reason?: string
    startDayOfMonth?: number
    startMonth?: number
    stopDayOfMonth?: number
    stopMonth?: number
}

export interface CoreGracePeriod {
    id: string
    moduleId: string
    forceGraceDays: number
    startDayOfMonth: number
    startMonth: number
    stopDayOfMonth: number
    stopMonth: number
}

export interface FormCoreGracePeriod {
    id?: string
    moduleId?: string
    forceGraceDays?: number
    startDayOfMonth?: number
    startMonth?: number
    stopDayOfMonth?: number
    stopMonth?: number
}