import React from 'react';

interface ToggleProps {
    title: string,
    value: boolean,
    id: string,
    disabled: boolean,
    update: (value: boolean) => void
}

const Toggle: React.FC<ToggleProps> = (props) => {
    return (
        <div className="toggle">
            <p>{props.title}</p>
            <div className="toggle-switch">
                <input
                    type="checkbox"
                    className="toggle-switch-checkbox"
                    onChange={e => props.update(e.target.checked)}
                    disabled={props.disabled}
                    name={props.id}
                    id={props.id}
                    checked={props.value}
                />
                <label className="toggle-switch-label" htmlFor={props.id}>
                    <span className="toggle-switch-inner"
                        data-yes="På"
                        data-no="Av"
                    />
                    <span className="toggle-switch-switch" />
                </label>
            </div>
        </div>
    )
}

export default Toggle;
