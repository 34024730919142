import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { CreateModuleGracePeriod, DeleteModuleGracePeriod, GetModuleGracePeriods, ModuleState, UpdateModuleGracePeriod } from '../../../store/ModuleAction';
import TableHorizontal from "../../misc/TableHorizontal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusCode from "../../../util/StatusCode";
import { Result, CoreGracePeriod, FormCoreGracePeriod } from "../../../models/CoreModels";
import { MeState } from '../../../store/MeAction';
import ModalComponent from "../../misc/ModalComponent";
import { Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap';
import ModulePeriodDateMonthPicker from "./ModulePeriodDateMonthPicker";
import { months } from './ModulePeriodDateMonthPicker';

const ActionName = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
};

interface GracePeriodState {
    module: ModuleState
    me: MeState
}

interface GracePeriodProps {
    moduleId: string
}

const validate = (period: FormCoreGracePeriod | undefined, validateNew: boolean) => {
    const validation = [];

    if (period === undefined) {
        return [];
    }

    if (period.forceGraceDays !== undefined && (period.forceGraceDays < 0 || Number.isNaN(period.forceGraceDays))) {
        validation.push("forceGraceDays");
    }

    if (validateNew) {
        if (!period.forceGraceDays) {
            validation.push("forceGraceDays");
        }
        if (!period.startDayOfMonth) {
            validation.push("startDayOfMonth");
        }
        if (!period.startMonth) {
            validation.push("startMonth");
        }
        if (!period.stopDayOfMonth) {
            validation.push("stopDayOfMonth");
        }
        if (!period.stopMonth) {
            validation.push("stopMonth");
        }
    }

    return validation;
}


const GracePeriods = (props: GracePeriodProps) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string | undefined>(undefined);
    const [period, setPeriod] = useState<FormCoreGracePeriod | undefined>(undefined);
    const [saveStatus, setSaveStatus] = useState<StatusCode>(StatusCode.NONE);

    const [validateNew, setValidateNew] = useState<boolean>(false);
    const validation = validate(period, validateNew);

    const isAdmin = useSelector<GracePeriodState, boolean>(state => state.me.isAdmin);
    const moduleGracePeriods = useSelector<GracePeriodState, Result<CoreGracePeriod[]>>(state => state.module.moduleGracePeriods);

    useEffect(() => {
        GetModuleGracePeriods(props.moduleId)(dispatch);
    }, [dispatch, props.moduleId]);

    useEffect(() => {
        if (saveStatus === StatusCode.COMPLETE) {
            setTimeout(() => {
                setSaveStatus(StatusCode.NONE);
                toggleModal();
                GetModuleGracePeriods(props.moduleId)(dispatch);
            }, 1500);
        }
    }, [saveStatus]);

    const update = async () => {
        setValidateNew(true);
        let updateValidation = validate(period, true)

        if (updateValidation.length !== 0) {
            return;
        }

        if (period === undefined) {
            return;
        }

        setSaveStatus(StatusCode.PENDING);
        if (name === ActionName.NEW) {
            const status = await CreateModuleGracePeriod(props.moduleId, period)(dispatch);
            setSaveStatus(status);
        } else if (name === ActionName.EDIT) {
            const status = await UpdateModuleGracePeriod(props.moduleId, period)(dispatch);
            setSaveStatus(status);
        } else if (name === ActionName.DELETE) {
            if (period?.id !== undefined) {
                const status = await DeleteModuleGracePeriod(props.moduleId, period.id)(dispatch);
                setSaveStatus(status);
            }
        }
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setName(undefined);
        setPeriod(undefined);
        setSaveStatus(StatusCode.NONE);
        setValidateNew(false);
    };

    const gracePeriods = () => {
        const gracePeriodInfo = moduleGracePeriods.data?.map(x => ({
            id: x.id,
            data: [
                { value: `${x.startDayOfMonth} ${months.find(m => m.value === x.startMonth)?.name}` },
                { value: `${x.stopDayOfMonth} ${months.find(m => m.value === x.stopMonth)?.name}` },
                { value: x.forceGraceDays },
                { value: <Button className="btn-icon" disabled={!isAdmin} onClick={() => { setName(ActionName.EDIT); setIsOpen(true); setPeriod(x); }}><FontAwesomeIcon icon="pen" title="Redigera graceperiod" /></Button> },
                { value: <Button className="btn-icon" disabled={!isAdmin} onClick={() => { setName(ActionName.DELETE); setIsOpen(true); setPeriod(x); }}><FontAwesomeIcon icon="trash" title="Radera graceperiod" /></Button> },
            ]
        })) ?? [];

        return <>
            <TableHorizontal
                header={[{ value: "Startdatum" }, { value: "Slutdatum" }, { value: "Antal dagar" }, { value: "", className: "w20px" }, { value: "", className: "w20px" }]}
                info={gracePeriodInfo}
                error={moduleGracePeriods.code === StatusCode.ERROR}
                searching={moduleGracePeriods.code === StatusCode.PENDING}
                showHeader={true}
                noResMsg="Den här modulen saknar graceperioder"
            />
            <Button color="primary" disabled={!isAdmin} onClick={() => { setIsOpen(true); setName(ActionName.NEW); setPeriod({}); }}>Skapa graceperiod</Button>
        </>
    };

    const onChangePeriod = (name: string, value: number | undefined) => {
        let updatedPeriod = { [name]: value };
        if (name === 'startMonth') {
            updatedPeriod.startDayOfMonth = undefined;
        }
        if (name === 'stopMonth') {
            updatedPeriod.stopDayOfMonth = undefined;
        }
        setPeriod({ ...period, ...updatedPeriod })
    }

    const gracePeriodModal = () => {
        const header = name === ActionName.NEW ? "Skapa" : name === ActionName.EDIT ? "Uppdatera" : name === ActionName.DELETE ? "Radera" : "";
        return (
            <ModalComponent
                isOpen={isOpen}
                toggleModal={toggleModal}
                header={`${header} Graceperiod`}
                cancel="Avbryt"
                confirm={header}
                saveStatus={saveStatus}
                update={() => update()}
                disabled={!isAdmin || validation.length > 0}
            >
                <Form>
                    <FormGroup>
                        <Label>Startdatum</Label>
                        <ModulePeriodDateMonthPicker
                            month={period?.startMonth}
                            date={period?.startDayOfMonth}
                            monthName="startMonth"
                            dateName="startDayOfMonth"
                            disabled={name === ActionName.DELETE || !isAdmin}
                            onChangePeriod={(name, value) => onChangePeriod(name, value)}
                            validation={validation}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Slutdatum</Label>
                        <ModulePeriodDateMonthPicker
                            month={period?.stopMonth}
                            date={period?.stopDayOfMonth}
                            monthName="stopMonth"
                            dateName="stopDayOfMonth"
                            disabled={name === ActionName.DELETE || !isAdmin}
                            onChangePeriod={(name, value) => onChangePeriod(name, value)}
                            validation={validation}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="forceGraceDays">Antal dagar</Label>
                        <Input
                            type="number"
                            name="forceGraceDays"
                            id="forceGraceDays"
                            placeholder="Antal dagar"
                            autoComplete="off"
                            value={period?.forceGraceDays || ""}
                            disabled={name === ActionName.DELETE || !isAdmin}
                            onChange={e => setPeriod({ ...period, "forceGraceDays": parseInt(e.target.value, 10) })}
                            invalid={validation.indexOf("forceGraceDays") !== -1}
                        />
                        <FormFeedback>Ange forceGraceDays</FormFeedback>
                    </FormGroup>
                </Form>
            </ModalComponent>
        );
    }

    return (
        <div className="mt-4">
            <h5>Graceperioder</h5>
            <p>Under följande perioder kommer modulens konfigurerade antal dagar för livscykelhantering att att ersättas med det valda värdet för perioden</p>

            {gracePeriods()}
            {isOpen && period && gracePeriodModal()}
        </div>
    );
}

export default GracePeriods;
