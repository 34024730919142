import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetModuleStopPeriods, ModuleState, CreateModuleStopPeriod, UpdateModuleStopPeriod, DeleteModuleStopPeriod } from '../../../store/ModuleAction';
import TableHorizontal from "../../misc/TableHorizontal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusCode from "../../../util/StatusCode";
import { Result, CoreStopPeriod, FormCoreStopPeriod } from "../../../models/CoreModels";
import { Button, Form, FormGroup, Label, FormFeedback, Input } from 'reactstrap';
import { MeState } from '../../../store/MeAction';
import ModalComponent from "../../misc/ModalComponent";
import ModulePeriodDateMonthPicker from "./ModulePeriodDateMonthPicker";
import { months } from './ModulePeriodDateMonthPicker';


const ActionName = {
    NEW: 'NEW',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
};

interface StopPeriodState {
    module: ModuleState
    me: MeState
}

interface StopPeriodProps {
    moduleId: string
}

const validate = (period: FormCoreStopPeriod | undefined, validateNew: boolean): string[] => {
    const validation = [];

    if (period === undefined) {
        return [];
    }

    if (period.reason === "") {
        validation.push("reason");
    }

    if (validateNew) {
        if (!period.reason) {
            validation.push("reason");
        }
        if (!period.startDayOfMonth) {
            validation.push("startDayOfMonth");
        }
        if (!period.startMonth) {
            validation.push("startMonth");
        }
        if (!period.stopDayOfMonth) {
            validation.push("stopDayOfMonth");
        }
        if (!period.stopMonth) {
            validation.push("stopMonth");
        }
    }

    return validation;
}

const StopPeriods = (props: StopPeriodProps) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [name, setName] = useState<string | undefined>(undefined);
    const [period, setPeriod] = useState<FormCoreStopPeriod | undefined>(undefined);
    const [saveStatus, setSaveStatus] = useState<StatusCode>(StatusCode.NONE);

    const [validateNew, setValidateNew] = useState<boolean>(false);
    const validation = validate(period, validateNew);

    const isAdmin = useSelector<StopPeriodState, boolean>(state => state.me.isAdmin);
    const moduleStopPeriods = useSelector<StopPeriodState, Result<CoreStopPeriod[]>>(state => state.module.moduleStopPeriods);

    useEffect(() => {
        GetModuleStopPeriods(props.moduleId)(dispatch);
    }, [dispatch, props.moduleId]);

    useEffect(() => {
        if (saveStatus === StatusCode.COMPLETE) {
            setTimeout(() => {
                setSaveStatus(StatusCode.NONE);
                toggleModal();
                GetModuleStopPeriods(props.moduleId)(dispatch);
            }, 1500);
        }
    }, [saveStatus]);

    const update = async () => {
        setValidateNew(true);
        let updateValidation = validate(period, true)

        if (updateValidation.length !== 0) {
            return;
        }

        if (period === undefined) {
            return;
        }

        setSaveStatus(StatusCode.PENDING);
        if (name === ActionName.NEW) {
            const status = await CreateModuleStopPeriod(props.moduleId, period)(dispatch);
            setSaveStatus(status);
        }
        else if (name === ActionName.EDIT) {
            const status = await UpdateModuleStopPeriod(props.moduleId, period)(dispatch);
            setSaveStatus(status);
        } else if (name === ActionName.DELETE) {
            if (period?.id !== undefined) {
                const status = await DeleteModuleStopPeriod(props.moduleId, period.id)(dispatch);
                setSaveStatus(status);
            }
        }
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
        setName(undefined);
        setPeriod(undefined);
        setSaveStatus(StatusCode.NONE);
        setValidateNew(false);
    };

    const stopPeriods = () => {
        const stopPeriodInfo = moduleStopPeriods.data?.map(x => ({
            id: x.id,
            data: [
                { value: `${x.startDayOfMonth} ${months.find(m => m.value === x.startMonth)?.name}` },
                { value: `${x.stopDayOfMonth} ${months.find(m => m.value === x.stopMonth)?.name}` },
                { value: x.reason },
                { value: <Button className="btn-icon" disabled={!isAdmin} onClick={() => { setName(ActionName.EDIT); setIsOpen(true); setPeriod(x); }}><FontAwesomeIcon icon="pen" title="Redigera stopperiod" /></Button> },
                { value: <Button className="btn-icon" disabled={!isAdmin} onClick={() => { setName(ActionName.DELETE); setIsOpen(true); setPeriod(x); }}><FontAwesomeIcon icon="trash" title="Radera stopperiod" /></Button> },
            ]
        })) ?? [];

        return <>
            <TableHorizontal
                header={[{ value: "Startdatum" }, { value: "Slutdatum" }, { value: "Loggmeddelande" }, { value: "", className: "w20px" }, { value: "", className: "w20px" }]}
                info={stopPeriodInfo}
                error={moduleStopPeriods.code === StatusCode.ERROR}
                searching={moduleStopPeriods.code === StatusCode.PENDING}
                showHeader={true}
                noResMsg="Den här modulen saknar stopperioder"
            />
            <Button color="primary" disabled={!isAdmin} onClick={() => { setIsOpen(true); setName(ActionName.NEW); setPeriod({}); }}>Skapa stopperiod</Button>
        </>
    };

    const onChangePeriod = (name: string, value: number | undefined) => {
        let updatedPeriod = { [name]: value };
        if (name === 'startMonth') {
            updatedPeriod.startDayOfMonth = undefined;
        }
        if (name === 'stopMonth') {
            updatedPeriod.stopDayOfMonth = undefined;
        }
        setPeriod({ ...period, ...updatedPeriod })
    }

    const stopPeriodModal = () => {
        const header = name === ActionName.NEW ? "Skapa" : name === ActionName.EDIT ? "Uppdatera" : name === ActionName.DELETE ? "Radera" : "";
        return (
            <ModalComponent
                isOpen={isOpen}
                toggleModal={toggleModal}
                header={`${header} Stopperiod`}
                cancel="Avbryt"
                confirm={header}
                saveStatus={saveStatus}
                update={() => update()}
                disabled={!isAdmin || validation.length > 0}
            >
                <Form>
                    <FormGroup>
                        <Label>Startdatum</Label>
                        <ModulePeriodDateMonthPicker
                            month={period?.startMonth}
                            date={period?.startDayOfMonth}
                            monthName="startMonth"
                            dateName="startDayOfMonth"
                            disabled={name === ActionName.DELETE || !isAdmin}
                            onChangePeriod={(name, value) => onChangePeriod(name, value)}
                            validation={validation}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Slutdatum</Label>
                        <ModulePeriodDateMonthPicker
                            month={period?.stopMonth}
                            date={period?.stopDayOfMonth}
                            monthName="stopMonth"
                            dateName="stopDayOfMonth"
                            disabled={name === ActionName.DELETE || !isAdmin}
                            onChangePeriod={(name, value) => onChangePeriod(name, value)}
                            validation={validation}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="reason">Anledning</Label>
                        <Input
                            type="text"
                            name="reason"
                            id="reason"
                            placeholder="Anledning"
                            autoComplete="off"
                            value={period?.reason || ""}
                            disabled={name === ActionName.DELETE || !isAdmin}
                            onChange={e => setPeriod({ ...period, "reason": e.target.value })}
                            invalid={validation.indexOf("reason") !== -1}
                        />
                        <FormFeedback>Ange anledning</FormFeedback>
                    </FormGroup>
                </Form>
            </ModalComponent>
        )
    }

    return (
        <div className="mt-4">
            <h5>Stopperioder</h5>
            <p>Under följande perioder kommer modulen ej att köras</p>
            {stopPeriods()}
            {isOpen && period && stopPeriodModal()}
        </div>
    );
}

export default StopPeriods;
