import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthState } from '../store/AuthAction';
import { MenuState, ToggleMenu } from '../store/MenuAction';
import SubFooter from './SubFooter';
import NavSection from '../util/NavSection';
import { FlowSyncAccessPolicy, isAuthorized } from '../models/FlowSyncAccessPolicy';
import { MeState } from '../store/MeAction';
import { ModuleState } from '../store/ModuleAction';
import { CoreMyModuleTypes, Result } from '../models/CoreModels';

interface NavMenuStoreState {
    auth: AuthState,
    menu: MenuState,
    me: MeState,
    module: ModuleState
}

interface NavMenuProps {
    activeSection?: string
}

const NavMenu: React.FC<NavMenuProps> = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { activeSection } = props;

    const [collapsed, setCollpased] = useState(true);

    const open = useSelector<NavMenuStoreState, { [key: string]: boolean }>(state => state.menu.open);
    const authorizedAccessPolicies = useSelector<NavMenuStoreState, FlowSyncAccessPolicy[]>(state => state.me.me?.authorizedAccessPolicies ?? []);
    const myModuleTypes = useSelector<NavMenuStoreState, Result<CoreMyModuleTypes>>(state => state.module.myModuleTypes);

    useEffect(() => {
        if (props.activeSection) {
            ToggleMenu(props.activeSection)(dispatch);
        }
    }, [dispatch])

    const toggleNavbar = useCallback(() => {
        setCollpased(!collapsed);
    }, [collapsed]);

    const toggleMenu = useCallback((name: string) => {
        ToggleMenu(name)(dispatch);
    }, [dispatch]);

    const renderSchoolAdmin = useMemo(() => {
        if (!isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.READ)) {
            return false;
        }

        return myModuleTypes?.data?.schoolAdmin ?? false;
    }, [authorizedAccessPolicies, myModuleTypes])
    
    const renderScheduleSync = useMemo(() => {
        if (!isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.READ)) {
            return false;
        }
        
        return myModuleTypes?.data?.scheduleSync ?? false;
    }, [authorizedAccessPolicies, myModuleTypes])
    
    const renderOneRoster = useMemo(() => {
        if (!isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.READ)) {
            return false;
        }
        
        return myModuleTypes?.data?.oneRoster ?? false;
    }, [authorizedAccessPolicies, myModuleTypes])

    const renderConfiguration = useMemo(() => {
        return isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.READ);
    }, [authorizedAccessPolicies])

    const renderPasswordManagement = useMemo(() => {
        return isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.EXPORT_UNEXPORTED_PASSWORD);
    }, [authorizedAccessPolicies])

    const activeTab = (url: string) => {
        return location.pathname.startsWith(url);
    }

    const symbols =  useCallback((name: string) => {
        return open[name] ? 
            <FontAwesomeIcon icon="chevron-down" className="arrow" /> : 
            <FontAwesomeIcon icon="chevron-right" className="arrow" />;
    }, [open])

    const active = useCallback((section: string) => 
        activeSection === section && !open[section] ? 'active' : ''
    , [activeSection, open])

    return (
        <Navbar className="dark">
            <NavbarBrand className="mr-auto brand"><img src="/FlowSync_lg_light.svg" alt="FlowSync" className="logo" /></NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} ><FontAwesomeIcon icon="bars" /> </NavbarToggler>
            <Collapse isOpen={!collapsed} navbar>
                <Nav navbar key={location.key}>

                    <NavItem>
                        <NavLink tag={RouterNavLink} to="/"><FontAwesomeIcon icon="network-wired" className="nav_icon" />Dashboard</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={RouterNavLink} to="/overview"><FontAwesomeIcon icon="diagram-project" className="nav_icon" />Synköversikt</NavLink>
                    </NavItem>

                    {renderPasswordManagement && <>
                        <NavItem>
                            <NavLink tag={RouterNavLink} to="/passwordadmin/passwords"><FontAwesomeIcon icon="key" className="nav_icon" />Lösenordsdistribution</NavLink>
                        </NavItem>
                    </>}

                    {renderSchoolAdmin && <React.Fragment>
                        <NavItem onClick={() => toggleMenu(NavSection.SCHOOLADMINISTRATION)}>
                            <NavLink href="#" className={active(NavSection.SCHOOLADMINISTRATION)}>Skoladministration<span className="open">{symbols(NavSection.SCHOOLADMINISTRATION)}</span></NavLink>
                        </NavItem>
                        {open[NavSection.SCHOOLADMINISTRATION] && <React.Fragment>
                            <NavItem>
                                <NavLink active={activeTab('/dashboard')} tag={RouterNavLink} to="/dashboard/" className="submenu"><FontAwesomeIcon icon="network-wired" className="nav_icon" />Översikt</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab('/schoolunit')} tag={RouterNavLink} to="/schoolunit/" className="submenu"><FontAwesomeIcon icon="school" className="nav_icon" />Skolor</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab('/activity')} tag={RouterNavLink} to="/activity/" className="submenu"><FontAwesomeIcon icon="calendar-alt" className="nav_icon" />Aktiviteter</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab('/group')} tag={RouterNavLink} to="/group/" className="submenu"><FontAwesomeIcon icon="users" className="nav_icon" />Grupper</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab('/user')} tag={RouterNavLink} to="/user/" className="submenu"><FontAwesomeIcon icon="user" className="nav_icon" />Personer</NavLink>
                            </NavItem>
                        </React.Fragment>}
                    </React.Fragment>}


                    {renderScheduleSync && <React.Fragment>
                        <NavItem onClick={() => toggleMenu(NavSection.SCHEDULESYNC)}>
                            <NavLink href="#" className={active(NavSection.SCHEDULESYNC)}>Schemapublicering<span className="open">{symbols(NavSection.SCHEDULESYNC)}</span></NavLink>
                        </NavItem>
                        {open[NavSection.SCHEDULESYNC] && <React.Fragment>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={activeTab('/schedulesync/user')} to="/schedulesync/user/" className="submenu"><FontAwesomeIcon icon="user" className="nav_icon" />Användare</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={activeTab('/schedulesync/event')} to="/schedulesync/event/" className="submenu"><FontAwesomeIcon icon="calendar-alt" className="nav_icon" />Schemapositioner</NavLink>
                            </NavItem>
                        </React.Fragment>}
                    </React.Fragment>}


                    {renderOneRoster && <React.Fragment>
                        <NavItem onClick={() => toggleMenu(NavSection.ONEROSTER)}>
                            <NavLink href="#" className={active(NavSection.ONEROSTER)}>OneRoster<span className="open">{symbols(NavSection.ONEROSTER)}</span></NavLink>
                        </NavItem>
                        {open[NavSection.ONEROSTER] && <React.Fragment>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={activeTab('/oneroster/dashboard')} to="/oneroster/dashboard" className="submenu"><FontAwesomeIcon icon="network-wired" className="nav_icon" />Översikt</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={activeTab('/oneroster/schoolunit')} to="/oneroster/schoolunit" className="submenu"><FontAwesomeIcon icon="school" className="nav_icon" />Skolor</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={activeTab('/oneroster/class')} to="/oneroster/class" className="submenu"><FontAwesomeIcon icon="calendar-alt" className="nav_icon" />Avsnitt</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={activeTab('/oneroster/user')} to="/oneroster/user" className="submenu"><FontAwesomeIcon icon="user" className="nav_icon" />Användare</NavLink>
                            </NavItem>
                        </React.Fragment>}
                    </React.Fragment>}

                    {renderConfiguration && <React.Fragment>
                        <NavItem onClick={() => toggleMenu(NavSection.CONFIGURATION)}>
                            <NavLink href="#" className={active(NavSection.CONFIGURATION)}>Konfiguration<span className="open">{symbols(NavSection.CONFIGURATION)}</span></NavLink>
                        </NavItem>
                        {open[NavSection.CONFIGURATION] && <React.Fragment>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={activeTab('/module')} to="/module/" className="submenu"><FontAwesomeIcon icon="file-import" className="nav_icon" />Moduler</NavLink>
                            </NavItem>
                            {isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.ADMIN) &&
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to="/auditlog/" className="submenu"><FontAwesomeIcon icon="list" className="nav_icon" />Granskningslogg</NavLink>
                                </NavItem>
                            }
                        </React.Fragment>}
                    </React.Fragment>}

                    <NavItem>
                        <SubFooter className="nav" />
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
    );
}

export default NavMenu;
