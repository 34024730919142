import { Action, Dispatch, Reducer } from 'redux';
import { CoreSyllabusFilter, RoleFilter, CoreDisplayableRef } from '../models/CoreModels';
import InstanceClient from '../services/InstanceClient';

export enum FilterActionType {
    GET_ROLES = 'GET_ROLES',
    GET_SYLLABUSES = 'GET_SYLLABUSES',
    GET_SCHOOL_UNITS = 'GET_SCHOOL_UNITS',
    GET_CLASS_GROUPS = 'GET_CLASS_GROUPS',
}

export interface FilterState {
    roles: RoleFilter[]
    syllabuses: CoreSyllabusFilter[]
    classGroups: CoreDisplayableRef[]
    schoolUnits: CoreDisplayableRef[]
}

interface Syllabuses extends Action<FilterActionType> {
    type: FilterActionType.GET_SYLLABUSES
    syllabuses: CoreSyllabusFilter[]
}

interface Roles extends Action<FilterActionType> {
    type: FilterActionType.GET_ROLES
    roles: RoleFilter[]
}

interface SchoolUnits extends Action<FilterActionType> {
    type: FilterActionType.GET_SCHOOL_UNITS
    schoolUnits: CoreDisplayableRef[]
}

interface ClassGroups extends Action<FilterActionType> {
    type: FilterActionType.GET_CLASS_GROUPS
    classGroups: CoreDisplayableRef[]
}

type FilterAction = Syllabuses | Roles | SchoolUnits | ClassGroups

export const getRoles = () => (dispatch: Dispatch<Roles> ) => {
    const url = '/api/core/filter/roles';
    InstanceClient.get<RoleFilter[]>(url)
        .then(res => {
            dispatch({
                type: FilterActionType.GET_ROLES,
                roles: res.data,
            })
        });
};

export const getSyllabuses = (schoolUnitIds: string[]) => (dispatch: Dispatch<Syllabuses>) => {
    const url = `/api/core/filter/syllabuses`;
    InstanceClient.get<CoreSyllabusFilter[]>(url, { schoolUnitIds })
        .then(res =>
            dispatch({
                type: FilterActionType.GET_SYLLABUSES,
                syllabuses: res.data,
            }));
}

export const getSchoolUnits = () => (dispatch: Dispatch<SchoolUnits>) => {
    const url = `/api/core/filter/schoolUnits`;
    InstanceClient.get<CoreDisplayableRef[]>(url)
        .then(res =>
            dispatch({
                type: FilterActionType.GET_SCHOOL_UNITS,
                schoolUnits: res.data,
            }));
}

export const getClassGroups = (schoolUnitId: string) => (dispatch: Dispatch<ClassGroups>) => {
    const url = `/api/core/filter/classGroups`;
    InstanceClient.get<CoreDisplayableRef[]>(url, { schoolUnitId })
        .then(res =>
            dispatch({
                type: FilterActionType.GET_CLASS_GROUPS,
                classGroups: res.data,
            }));
}

export const clearClassGroups = () => (dispatch: Dispatch<ClassGroups>) => {
    dispatch({
        type: FilterActionType.GET_CLASS_GROUPS,
        classGroups: [],
    })
}



const initialState: FilterState = {
    roles: [],
    syllabuses: [],
    schoolUnits: [],
    classGroups: [],
}

export const reducer: Reducer<FilterState, FilterAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case FilterActionType.GET_ROLES:
            return {
                ...state,
                roles: action.roles,
            };
        case FilterActionType.GET_SYLLABUSES:
            return {
                ...state,
                syllabuses: action.syllabuses,
            };
        case FilterActionType.GET_CLASS_GROUPS:
            return {
                ...state,
                classGroups: action.classGroups,
            };
        case FilterActionType.GET_SCHOOL_UNITS:
            return {
                ...state,
                schoolUnits: action.schoolUnits,
            };
        default:
            return state;
    }
};
