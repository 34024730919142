import React from "react";
import { useParams } from 'react-router-dom';
import StopPeriods from './StopPeriods';
import GracePeriods from './GracePeriods';
import { useSelector } from 'react-redux';


const ModulePeriods = () => {
    const { moduleId } = useParams();
    const module = useSelector(state => state.module.moduleBasicInfo);

    return (
        <div className="mt-4">
            <StopPeriods
                moduleId={moduleId}
            />
            {module.data?.displayGracePeriod &&
                <GracePeriods
                    moduleId={moduleId}
                />
            }
        </div>
    )
}

export default ModulePeriods;
