import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { CoreUserRelation } from "../../../models/CoreUser";
import StatusCode from "../../../util/StatusCode";
import TableHorizontal from "../../misc/TableHorizontal";
import * as UserRelationTypes from '../../../util/UserRelationUtil';
import * as Helper from '../../../util/Helper';
import { GetUserRelations, UserState } from "../../../store/UserAction";
import { RequestState } from "../../../models/RequestState";
import { InfoObject } from "../../misc/TableHorizontalPagedToggle";

interface UserViewUserRelationsState {
    users: UserState
};


const UserViewUserRelations = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const relations = useSelector<UserViewUserRelationsState, RequestState<CoreUserRelation[]>>(state => state.users.userRelation);

    useEffect(() => {
        if (userId !== undefined) {
            GetUserRelations(userId)(dispatch);
        }
    }, [dispatch, userId]);

    const mapRelations = (): InfoObject[] => {
        if (relations.code !== StatusCode.COMPLETE) {
            return [];
        }

        return relations.data.map(ur => ({
            id: ur.id,
            data: [
                { value: <Link to={`/user/${ur.userId}`}>{ur.displayName}</Link> },
                { value: ur.schoolUnit ? <Link to={`/user/${ur.schoolUnit.id}`}>{ur.schoolUnit.title}</Link> : ''  },
                { value: UserRelationTypes.relationTypeById(ur.relationType, ur.isChild) },
                { value: Helper.active(ur.active) },
            ]
        }))
    }

    const renderUserRelations = () => {
        const relationsInfo = mapRelations();
        return (
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Skola' }, { value: 'Relation' }, { value: 'Aktiv' }]}
                info={relationsInfo}
                searching={relations.code === StatusCode.PENDING}
                error={relations.code === StatusCode.ERROR}
                displayName="Personrelationer"
                noResMsg="Den här personen saknar personrelationer"
            />
        );
    }

    return (<>
        {renderUserRelations()}
    </>)
}

export default UserViewUserRelations;
