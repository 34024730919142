import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusCode from "../../../util/StatusCode";
import TableHorizontal from "../../misc/TableHorizontal";
import PrimarySchoolModal from "./PrimarySchoolModal";
import * as Helper from '../../../util/Helper';
import * as PrimarySchoolTypes from '../../../util/PrimarySchoolUtil';
import { CoreDisplayableRef } from "../../../models/CoreModels";
import { DeleteUserPrimarySchool, GetUserSchoolUnits, ResetPrimarySchoolSaveStatus, SetUserPrimarySchool, UserSchoolUnitsRequestState, UserState } from "../../../store/UserAction";
import { CoreUserDuty, CoreUserEnrollment } from "../../../models/CoreUser";
import { CorePrimarySchoolStatus } from "../../../models/CoreUser";
import { MeState } from "../../../store/MeAction";
import { FlowSyncAccessPolicy, isAuthorized } from "../../../models/FlowSyncAccessPolicy";
import * as SchoolTypeUtil from '../../../util/SchoolTypeUtil';
import ActiveToggle from "../../misc/ActiveToggle";
import { EmptyRequestState } from "../../../models/RequestState";

type PrimarySchoolOption = {
    primarySchoolStatus: CorePrimarySchoolStatus
    schoolUnit: CoreDisplayableRef
}

interface UserViewSchoolUnitsState {
    users: UserState
    me: MeState
}

const getPrimarySchoolOptions = (userSchoolUnits: UserSchoolUnitsRequestState): PrimarySchoolOption[] => {
    const uniqueOptions: Map<string, PrimarySchoolOption> = new Map<string, PrimarySchoolOption>();

    if (userSchoolUnits.code !== StatusCode.COMPLETE) {
        return Array.from(uniqueOptions.values());
    }

    const memberships: (CoreUserDuty | CoreUserEnrollment)[] = Helper.ensureArray(userSchoolUnits.data?.duties)
    const enrollments = Helper.ensureArray(userSchoolUnits.data?.enrollments);

    memberships.concat(enrollments).forEach(membership => {
        if (!membership.primarySchoolStatus || membership.primarySchoolStatus.status === PrimarySchoolTypes.Types.DEFAULT) {
            return;
        }

        if (!membership.schoolUnit || uniqueOptions.has(membership.schoolUnit.id)) {
            return;
        }

        uniqueOptions.set(membership.schoolUnit.id, {
            primarySchoolStatus: membership.primarySchoolStatus,
            schoolUnit: membership.schoolUnit,
        });
    });

    return Array.from(uniqueOptions.values());
}

const UserViewSchoolUnits = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const userSchoolUnits = useSelector<UserViewSchoolUnitsState, UserSchoolUnitsRequestState>(state => state.users.userSchoolUnits);
    const authorizedAccessPolicies = useSelector<UserViewSchoolUnitsState, FlowSyncAccessPolicy[]>(state => state.me.me?.authorizedAccessPolicies ?? []);
    const primarySchoolSaveStatus = useSelector<UserViewSchoolUnitsState, EmptyRequestState>(state => state.users.primarySchool);

    const [open, setOpen] = useState(false);
    const [primarySchool, setPrimarySchool] = useState<PrimarySchoolOption | undefined>(undefined);
    const [selectableSchoolUnits, setSelectableSchoolUnits] = useState<PrimarySchoolOption[] | []>([]);

    useEffect(() => {
        if (userId) {
            GetUserSchoolUnits(userId, false, {})(dispatch);
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (primarySchoolSaveStatus.code === StatusCode.COMPLETE && userId) {
            setTimeout(() => {
                GetUserSchoolUnits(userId, true, { ...userSchoolUnits.filter })(dispatch);
                setOpen(false);
                ResetPrimarySchoolSaveStatus()(dispatch);
            }, 1500);
        }
    });

    useEffect(() => {
        if (userSchoolUnits.code === StatusCode.COMPLETE) {
            const selectableSchoolUnitsList = getPrimarySchoolOptions(userSchoolUnits)
                .sort((a, b) => a.schoolUnit.title! > b.schoolUnit.title! ? 1 : -1);

            setSelectableSchoolUnits(selectableSchoolUnitsList);

            const selectedPrimary = selectableSchoolUnitsList.find(x => x.primarySchoolStatus.overriddenPrimary) ||
                selectableSchoolUnitsList.find(x => x.primarySchoolStatus.status === PrimarySchoolTypes.Types.DEFAULTPRIMARY);
            if (selectedPrimary) {
                setPrimarySchool(selectedPrimary);
            }
        }
    }, [userSchoolUnits]);

    const updateOnlyActive = (value: boolean) => {
        if (userId) {
            GetUserSchoolUnits(userId, true, { ...userSchoolUnits.filter, onlyActive: value })(dispatch);
        };
    };

    const updatePrimarySchool = async (updatedSchool: string) => {
        if (userId) {
            SetUserPrimarySchool(userId, updatedSchool)(dispatch);
        };
    };

    const deleteOverridenPrimarySchool = async () => {
        if (userId) {
            DeleteUserPrimarySchool(userId)(dispatch);
        }
    }

    const closeModal = (): void => {
        setOpen(false);
        ResetPrimarySchoolSaveStatus()(dispatch);
    }

    const renderModal = () => (
        <PrimarySchoolModal
            isOpen={open}
            toggleModal={() => closeModal()}
            saveStatus={primarySchoolSaveStatus.code}
            deleteOverridenPrimarySchool={() => deleteOverridenPrimarySchool()}
            items={selectableSchoolUnits}
            primarySchool={primarySchool}
            getIcons={primarySchoolStatus => getIcons(primarySchoolStatus)}
            update={(updatedSchool) => updatePrimarySchool(updatedSchool)}
            disabled={!isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.MAP)}
        />
    );

    const getIcons = (primarySchoolStatus: CorePrimarySchoolStatus) => {
        let icon = [];
        if (primarySchoolStatus?.overriddenPrimary) {
            icon.push(<FontAwesomeIcon key='green' className="me-2" title="Vald primär skola" color="orange" icon="crown" />);
        }
        if (primarySchoolStatus?.status === PrimarySchoolTypes.Types.DEFAULTPRIMARY) {
            icon.push(<FontAwesomeIcon key='grey' className="me-2" title="Beräknad primär skola" color="grey" icon="check" />);
        }
        return icon;
    }

    const iconWithPen = (schoolUnitId: string, first: boolean, primarySchoolStatus?: CorePrimarySchoolStatus) => {
        if (primarySchoolStatus) {
            const icons = getIcons(primarySchoolStatus);
            const allowedEdit = isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.MAP);
            const displayPen = icons.length >= 1 && selectableSchoolUnits.length > 1 && primarySchool?.schoolUnit.id === schoolUnitId && first && allowedEdit;
            return (<>
                {icons}
                {displayPen && <FontAwesomeIcon className="c-pointer" title="Redigera primär skola" icon="pen" onClick={() => setOpen(true)} />}
            </>);
        }
        return '-';
    }

    const hasData = () => {
        if (userSchoolUnits.code === StatusCode.COMPLETE) {
            return userSchoolUnits.data;
        }
        return null;
    }

    const renderEnrollmentSchoolUnits = () => {
        const handled: string[] = [];
        const isHandled = (schoolUnitId: string) => handled.some(s => s === schoolUnitId);

        const enrollmentSchools = hasData()?.enrollments
            .filter(x => x.schoolUnit !== undefined)
            .map(x => {
                var first = !isHandled(x.schoolUnit!.id);
                if (x.primarySchoolStatus) {
                    handled.push(x.schoolUnit!.id)
                }
                return ({
                    id: x.toString(),
                    data: [
                        { value: <Link to={`/schoolunit/${x.schoolUnit!.id}`} > {x.schoolUnit!.title} </Link> },
                        { value: x.schoolType ? SchoolTypeUtil.getTypes(x.schoolType?.id) : 'Okänt' },
                        { value: x.schoolYear },
                        { value: x.educationCode },
                        { value: x.active !== undefined ? Helper.active(x.active) : '-' },
                        { value: Helper.formatDateOnly(x.startDate) },
                        { value: Helper.formatDateOnly(x.endDate) },
                        { value: iconWithPen(x.schoolUnit!.id, first, x.primarySchoolStatus) },
                    ]
                })
            }) ?? [];
        return (
            <TableHorizontal
                header={[{ value: 'Inskrivning' }, { value: 'Utbildningsform' }, { value: 'Årskurs' }, { value: 'Studievägskod' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }, { value: <>Primär <a href="https://flowsync.se/help/schooladmin/primarySchool" target="_blank" rel="noreferrer" > <FontAwesomeIcon className="documentation-icon small" title="Visa hjälpavsnitt" icon="question-circle" /></a> </>, dataLabel: "Primär" }]}
                info={enrollmentSchools}
                displayName="Inskrivningar"
                searching={userSchoolUnits.code === StatusCode.PENDING}
                error={userSchoolUnits.code === StatusCode.ERROR}
                noResMsg="Den här personen saknar inskrivningar"
            />
        );
    };

    const renderDutySchoolUnits = () => {
        const handled: string[] = [];
        const isHandled = (schoolUnitId: string) => handled.some(s => s === schoolUnitId);

        const dutySchools = hasData()?.duties
            .filter(x => x.schoolUnit !== undefined)
            .map(x => {
                var first = !isHandled(x.schoolUnit!.id);
                if (x.primarySchoolStatus) {
                    handled.push(x.schoolUnit!.id)
                }
                return ({
                    id: x.toString(),
                    data: [
                        { value: <Link to={`/schoolunit/${x.schoolUnit!.id}`} > {x.schoolUnit!.title} </Link> },
                        { value: x.dutyRole !== undefined ? x.dutyRole.name : '-' },
                        { value: x.signature },
                        { value: x.dutyPercent &&`${x.dutyPercent} %` },
                        { value: x.active !== undefined ? Helper.active(x.active) : '-' },
                        { value: Helper.formatDateOnly(x.startDate) },
                        { value: Helper.formatDateOnly(x.endDate) },
                        { value: iconWithPen(x.schoolUnit!.id, first, x.primarySchoolStatus) },
                    ]
                })
            }) ?? [];
        return (
            <TableHorizontal
                header={[{ value: 'Tjänstgöring' }, { value: 'Roll' }, { value: 'Signatur'}, { value: 'Tjänstgöringsgrad' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }, { value: <>Primär <a href="https://flowsync.se/help/schooladmin/primarySchool" target="_blank" rel="noreferrer" > <FontAwesomeIcon className="documentation-icon small" title="Visa hjälpavsnitt" icon="question-circle" /></a> </>, dataLabel: "Primär" }]}
                info={dutySchools}
                displayName="Tjänstgöringar"
                searching={userSchoolUnits.code === StatusCode.PENDING}
                error={userSchoolUnits.code === StatusCode.ERROR}
                noResMsg="Den här personen saknar tjänstgöringar"
            />
        );
    };

    return (<>
        <div className="header_member_table large">Skolkopplingar
            <span className="right">
                <ActiveToggle
                    onlyActive={userSchoolUnits.filter.onlyActive ?? true}
                    update={updateOnlyActive}
                    disabled={userSchoolUnits.code === StatusCode.PENDING}
                />
            </span>
        </div>
        {renderDutySchoolUnits()}
        {renderEnrollmentSchoolUnits()}
        {open && renderModal()}
    </>)
}

export default UserViewSchoolUnits;
