import React from 'react';
import Toggle from './Toggle';

type UpdateCallback = (checked: boolean) => void;

interface ActivityToggleProps {
    id?: string
    onlyActive: boolean
    disabled: boolean
    update: UpdateCallback
}

const ActiveToogle: React.FC<ActivityToggleProps> = (props) => {
    let onlyActive = props.onlyActive !== null ? !props.onlyActive : false;

    return <Toggle
        title="Visa inaktiva"
        value={onlyActive}
        id={`active-${props.id}`}
        disabled={props.disabled}
        update={(value) => props.update(!value)}
    />;
}

export default ActiveToogle;
