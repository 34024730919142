import { CoreDisplayableRef } from "./CoreModels"
import { PageRequest } from "./Requests"

export interface PasswordAdminAccountRequest extends PageRequest {
    classGroupId?: string,
    showExported?: boolean,
    schoolUnitId?: string,
    search?: string,
    role?: CorePasswordAdminAccountRole
}

export enum CorePasswordAdminAccountRole {
    STUDENT = 1,
    STAFF = 2
}

export interface CorePasswordAdminAccount {
    userTrackingId: string,
    user: CoreDisplayableRef,
    username?: string,
    emailAddress?: string,
    schoolUnit?: CoreDisplayableRef,
    classGroup?: CoreDisplayableRef,
    role?: CorePasswordAdminAccountRole,
    exported: boolean,
    exportedAt?: string, // timestamp
    exportedBy?: string
}
