import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import * as Tab from '../../util/TabValue';
import RenderTabs from '../misc/Tabs';
import ModuleStatus from './ModuleStatus';
import ModuleInstanceRun from './ModuleInstanceRun';
import ModuleView from './ModuleView';
import ModulePeriods from './modulePeriods/ModulePeriods';
import { getModuleBasicInfo, resetModuleBasicInfo } from '../../store/ModuleAction';


const Configuration = () => {
    const { moduleId } = useParams();
    const dispatch = useDispatch();
    const module = useSelector(state => state.module.moduleBasicInfo);

    useEffect(() => {
        getModuleBasicInfo(moduleId)(dispatch);
        return () => {
            resetModuleBasicInfo()(dispatch);
        }
    }, [dispatch]);
    
    let tabs = [];
    if (module.data?.runnable) {
        tabs.push(Tab.Tabs.Status);
        tabs.push(Tab.Tabs.ModuleInstanceRun);
    } 
    tabs.push(Tab.Tabs.Configuration)
    tabs.push(Tab.Tabs.Periods)

    if (module?.error === 400 || module?.error === 404) {
        return <NotFound />;
    }

    const displayName = module.data?.title ? ' / ' + module.data.title : '';

    return (
        <div className="padding-40 container single">
            <h1><Link to="/module">Modul</Link> {displayName}</h1>

            <RenderTabs
                tabs={tabs}
                url="module"
                id={moduleId} />
            <div className="tab-border"/>

            <Routes>
                <Route path={Tab.Tabs.Status.url} element={<ModuleStatus />} />
                <Route path={Tab.Tabs.ModuleInstanceRun.url} element={<ModuleInstanceRun />} />
                <Route path={Tab.Tabs.Configuration.url} element={<ModuleView />} />
                <Route path={Tab.Tabs.Periods.url} element={<ModulePeriods />} />
                <Route path="*" element={<Navigate replace={true} to={`/module/${moduleId}/${Tab.Tabs.Status.url}`} />} />
            </Routes>
        </div>
    );
}

export const NotFound = () => {
    return (
        <div className="padding-40 container single">
            <h1><Link to="/module">Modul</Link></h1>
            <p>Modulen hittades inte. <Link to='/module'>Tillbaka</Link> till modulöversikten</p>
        </div>
    );
}

export default Configuration;