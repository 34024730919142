import React from "react";
import { FormGroup, Input, FormFeedback } from 'reactstrap';

interface ModulePeriodDateMonthPickerProps {
    month: number | undefined
    date: number | undefined
    monthName: string
    dateName: string
    disabled: boolean
    onChangePeriod: (name: string, value: number) => void
    validation: string[]
}

export const months = [{ name: "Januari", value: 1 }, { name: "Februari", value: 2 }, { name: "Mars", value: 3 }, { name: "April", value: 4 }, { name: "Maj", value: 5 }, { name: "Juni", value: 6 }, { name: "Juli", value: 7 }, { name: "Augusti", value: 8 }, { name: "September", value: 9 }, { name: "Oktober", value: 10 }, { name: "November", value: 11 }, { name: "December", value: 12 }];


const ModulePeriodDateMonthPicker: React.FC<ModulePeriodDateMonthPickerProps> = (props) => {

    const month = props.month;
    const dates = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }, { value: 6 }, { value: 7 }, { value: 8 }, { value: 9 }, { value: 10 }, { value: 11 }, { value: 12 }, { value: 13 }, { value: 14 }, { value: 15 }, { value: 16 }, { value: 17 }, { value: 18 }, { value: 19 }, { value: 20 }, { value: 21 }, { value: 22 }, { value: 23 }, { value: 24 }, { value: 25 }, { value: 26 }, { value: 27 }, { value: 28 }, { value: 29, disabled: month === 2 }, { value: 30, disabled: month === 2 }, { value: 31, disabled: month === 2 || month === 4 || month === 6 || month === 9 || month === 11 }]

    return (
        <FormGroup>
            <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '48%' }}>
                    <Input
                        type="select"
                        value={props.month || ''}
                        disabled={props.disabled}
                        onChange={e => props.onChangePeriod(props.monthName, parseInt(e.target.value))}
                        invalid={props.validation.indexOf(props.monthName) !== -1}
                    >
                        <option value="" disabled>Välj månad</option>
                        {months.map(m => <option value={m.value}>{m.name}</option>)}
                    </Input>
                    <FormFeedback>Ange ett datum</FormFeedback>
                </div>
                <div style={{ width: '48%' }}>
                    <Input
                        type="select"
                        value={props.date || ''}
                        disabled={props.disabled}
                        onChange={e => props.onChangePeriod(props.dateName, parseInt(e.target.value))}
                        invalid={props.validation.indexOf(props.dateName) !== -1}
                    >
                        <option value="" disabled>Välj datum</option>
                        {dates.map(m => <option value={m.value} disabled={m.disabled}>{m.value}</option>)}
                    </Input>
                    <FormFeedback>Ange ett datum</FormFeedback>
                </div>
            </FormGroup>
        </FormGroup>
    )
}

export default ModulePeriodDateMonthPicker;
