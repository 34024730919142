import { IconProp } from "@fortawesome/fontawesome-svg-core"

export enum Value {
    INFORMATION = 'INFORMATION',
    OBJECTLOGS = 'OBJECTLOGS',
    MEMBERS = 'MEMBERS',
    TRACKINGS = 'TRACKINGS',
    ACTIVITIES = 'ACTIVITIES',
    USERRELATIONS = 'USERRELATIONS', 
    USERSCHOOLUNITS = 'USERSCHOOLUNITS',
    GROUPS = 'GROUPS',
    SCHEDULE = 'SCHEDULE',
    CLASSES = 'CLASSES',
    STATUS = 'STATUS',
    CONFIGURATION = 'CONFIGURATION',
    MODULEINSTANCERUN = 'MODULEINSTANCERUN',
    INSTANCELOGS = 'INSTANCELOGS',
    INSTANCEPHASESTATISTICS = 'INSTANCEPHASESTATISTICS',
    SYLLABUS = 'SYLLABUS',
    PERIODS = 'PERIODS',
}

export interface Tab {
    text: string,
    value: string,
    url: string,
    icon: IconProp
}

export class Tabs {
    static Information: Tab = { text: "Information", value: Value.INFORMATION, url: "information", icon: "circle-info" }
    static ObjectLogs: Tab = { text: "Logginformation", value: Value.OBJECTLOGS, url: "objectLogs", icon: "exclamation-triangle" }
    static Members: Tab = { text: "Medlemmar", value: Value.MEMBERS, url: "members", icon: "users" }
    static Trackings: Tab = { text: "Källor", value: Value.TRACKINGS, url: "trackings", icon: "shoe-prints" }
    static Activities: Tab = { text: "Aktiviteter", value: Value.ACTIVITIES, url: "activities", icon: "clipboard" } 
    static UserRelations: Tab = { text: "Personrelationer", value: Value.USERRELATIONS, url: "userrelations", icon: "user" }
    static UserSchoolUnits: Tab = { text: "Skolkopplingar", value: Value.USERSCHOOLUNITS, url: "schoolUnits", icon: "school" }
    static Groups: Tab = { text: "Grupper", value: Value.GROUPS, url: "groups", icon: "users-rectangle" } 
    static Schedule: Tab = { text: "Schemapositioner", value: Value.SCHEDULE, url: "schedule", icon: "calendar-alt" } 
    static Classes: Tab = { text: "Avsnitt", value: Value.CLASSES, url: "classes", icon: "users-rectangle" } 
    static Status: Tab = { text: "Status", value: Value.STATUS, url: "status", icon: "chart-simple" }
    static Configuration: Tab = { text: "Konfiguration", value: Value.CONFIGURATION, url: "configuration", icon: "cog" }
    static ModuleInstanceRun: Tab = { text: "Körningar", value: Value.MODULEINSTANCERUN, url: "moduleInstanceRun", icon: "car-side" } 
    static InstanceLogs: Tab = { text: "Instanslogg", value: Value.INSTANCELOGS, url: "instancelogs", icon: "list-check" }
    static InstancePhaseStatistics: Tab = { text: "Statistik", value: Value.INSTANCEPHASESTATISTICS, url: "phaseStatistics", icon: "chart-bar" }
    static Syllabus: Tab = { text: "Kursplan", value: Value.SYLLABUS, url: "syllabus", icon: "scroll" }
    static Periods: Tab = { text: "Perioder", value: Value.PERIODS, url: "periods", icon: "calendar-alt" } 
}
