import { Action, Reducer, Dispatch } from 'redux';
import InstanceClient from '../services/InstanceClient';
import { PageResult } from '../models/CoreModels';
import { CacheRequestState, RequestState } from '../models/RequestState';
import StatusCode from '../util/StatusCode';
import { CorePasswordAdminAccount, PasswordAdminAccountRequest } from '../models/CorePasswordAdminModels';

export enum PasswordAdminActionType {
    LIST_ACCOUNT_PASSWORDS = 'LIST_ACCOUNT_PASSWORDS',
    LIST_ACCOUNT_PASSWORDS_FILTER = 'LIST_ACCOUNT_PASSWORDS_FILTER',
    LIST_ACCOUNT_PASSWORDS_UPDATE = 'LIST_ACCOUNT_PASSWORDS_UPDATE'
}

export interface PasswordAdminState {
    passwordAccounts: CacheRequestState<PageResult<CorePasswordAdminAccount>>
}

interface ListAccountPasswordsAction extends Action<PasswordAdminActionType.LIST_ACCOUNT_PASSWORDS> {
    passwordAccounts: RequestState<PageResult<CorePasswordAdminAccount>>
}

interface ListAccountPasswordsUpdateAction extends Action<PasswordAdminActionType.LIST_ACCOUNT_PASSWORDS_UPDATE> {
}


type PasswordAdminAction = ListAccountPasswordsAction
    | ListAccountPasswordsUpdateAction

    
export const listAccountPasswords = (filter: PasswordAdminAccountRequest = {}) => (dispatch: Dispatch<PasswordAdminAction>) => {
    dispatch({ type: PasswordAdminActionType.LIST_ACCOUNT_PASSWORDS_UPDATE });
    
    const url = '/api/core/passwordadmin/passwords';
    InstanceClient.get<PageResult<CorePasswordAdminAccount>>(url, filter)
        .then(res =>
            dispatch({
                type: PasswordAdminActionType.LIST_ACCOUNT_PASSWORDS,
                passwordAccounts: { code: StatusCode.COMPLETE, data: res.data, }
            })
        )
        .catch(error =>
            dispatch({
                type: PasswordAdminActionType.LIST_ACCOUNT_PASSWORDS,
                passwordAccounts: { code: StatusCode.ERROR, error }
            })
        );
};

export const exportPasswordsFilter = (filter: PasswordAdminAccountRequest = {}) => {
    return InstanceClient.get(`/api/core/passwordadmin/export`, filter)
        .then((res) => {
            const data = res.data as ArrayBuffer;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            const now = new Date();
            let fileName = `passwordexport ${now.getUTCFullYear()}-${now.getUTCMonth()}-${now.getUTCDate()}.csv`;

            const contentDisposition = res.headers['content-disposition'];
            if (contentDisposition) {
                var matches = /filename=([^;]*);/.exec(contentDisposition);
                if (matches !== null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
}

export const exportPasswordsSelected = (ids: string[] = []) => {
    return InstanceClient.post(`/api/core/passwordadmin/export`, ids)
        .then((res) => {
            const data = res.data as ArrayBuffer;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            const now = new Date();
            let fileName = `passwordexport ${now.getUTCFullYear()}-${now.getUTCMonth()}-${now.getUTCDate()}.csv`;

            const contentDisposition = res.headers['content-disposition'];
            if (contentDisposition) {
                var matches = /filename=([^;]*);/.exec(contentDisposition);
                if (matches !== null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
} 

const initialState: PasswordAdminState = {
    passwordAccounts: { code: StatusCode.NONE },
};
    
export const reducer: Reducer<PasswordAdminState, PasswordAdminAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case PasswordAdminActionType.LIST_ACCOUNT_PASSWORDS_UPDATE:
            return {
                ...state,
                passwordAccounts: { ...state.passwordAccounts, code: StatusCode.PENDING }
            };
        case PasswordAdminActionType.LIST_ACCOUNT_PASSWORDS:
            return {
                ...state,
                passwordAccounts: action.passwordAccounts
            };
        default:
            return state;
    }
}