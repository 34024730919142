import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import store from './store';

const SET_MODULE_STATE_PROP = 'SET_MODULE_STATE_PROP';
const GET_INSTANCE_PAGE = 'GET_INSTANCE_PAGE';
const GET_INSTANCELOGS = 'GET_INSTANCELOGS';
const GET_INSTANCELOGS_UPDATE = 'GET_INSTANCELOGS_UPDATE';
const GET_OBJECT_LOGS = 'GET_OBJECT_LOGS';
const GET_BASIC_INFO = 'GET_BASIC_INFO';

const initialState = {
    selectableTriggers: { code: StatusCode.PENDING },
    modules: { code: StatusCode.PENDING },
    module: { code: StatusCode.PENDING },
    moduleInstance: { code: StatusCode.PENDING },
    moduleBasicInfo: { code: StatusCode.PENDING },
    updatedModule: { code: StatusCode.NONE },
    createdModule: { code: StatusCode.NONE },
    moduleTypes: { code: StatusCode.PENDING },
    moduleStatus: { code: StatusCode.PENDING },
    startRequest: { code: StatusCode.NONE },
    systemNames: { code: StatusCode.NONE },
    instances: { code: StatusCode.PENDING },
    instanceLogs: { code: StatusCode.PENDING },
    objectLogs: { code: StatusCode.PENDING },
    myModuleTypes: { code: StatusCode.NONE },
    eventIds: { code: StatusCode.PENDING },
    moduleStopPeriods: { code: StatusCode.PENDING },
    createModuleStopPeriod: { code: StatusCode.NONE },
    updateModuleStopPeriod: { code: StatusCode.NONE },
    deleteModuleStopPeriod: { code: StatusCode.NONE },
    moduleGracePeriods: { code: StatusCode.PENDING },
    createModuleGracePeriod: { code: StatusCode.NONE },
    updateModuleGracePeriod: { code: StatusCode.NONE },
    deleteModuleGracePeriod: { code: StatusCode.NONE },
}

export const GetModuleStopPeriods = (moduleId) => dispatch => {
    return send(dispatch, { prop: 'moduleStopPeriods', url: `/api/core/module/${moduleId}/moduleStopPeriods`, dispatchPending: true })
}

export const CreateModuleStopPeriod = (moduleId, stopPeriod) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createModuleStopPeriod', status: { code: StatusCode.PENDING } })
    return InstanceClient.post(`/api/core/module/${moduleId}/createStopPeriod`, stopPeriod)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createModuleStopPeriod', status: { code: StatusCode.COMPLETE } });
            return StatusCode.COMPLETE;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createModuleStopPeriod', status: { code: StatusCode.ERROR, error } });
            return StatusCode.ERROR;
        })
}

export const UpdateModuleStopPeriod = (moduleId, stopPeriod) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updateModuleStopPeriod', status: { code: StatusCode.PENDING } })
    return InstanceClient.put(`/api/core/module/${moduleId}/editStopPeriod`, stopPeriod)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updateModuleStopPeriod', status: { code: StatusCode.COMPLETE } });
            return StatusCode.COMPLETE;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updateModuleStopPeriod', status: { code: StatusCode.ERROR, error } });
            return StatusCode.ERROR;
        })
}

export const DeleteModuleStopPeriod = (moduleId, id) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'deleteModuleStopPeriod', status: { code: StatusCode.PENDING } })
    return InstanceClient.delete(`/api/core/module/${moduleId}/deleteStopPeriod/${id}`)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'deleteModuleStopPeriod', status: { code: StatusCode.COMPLETE } });
            return StatusCode.COMPLETE;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'deleteModuleStopPeriod', status: { code: StatusCode.ERROR, error } });
            return StatusCode.ERROR;
        })
}

export const GetModuleGracePeriods = (moduleId) => dispatch => {
    return send(dispatch, { prop: 'moduleGracePeriods', url: `/api/core/module/${moduleId}/moduleGracePeriods`, dispatchPending: true })
}

export const CreateModuleGracePeriod = (moduleId, gracePeriod) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createModuleGracePeriod', status: { code: StatusCode.PENDING } })
    return InstanceClient.post(`/api/core/module/${moduleId}/createGracePeriod`, gracePeriod)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createModuleGracePeriod', status: { code: StatusCode.COMPLETE } });
            return StatusCode.COMPLETE;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createModuleGracePeriod', status: { code: StatusCode.ERROR, error } });
            return StatusCode.ERROR;
        })
}

export const UpdateModuleGracePeriod = (moduleId, gracePeriod) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updateModuleGracePeriod', status: { code: StatusCode.PENDING } })
    return InstanceClient.put(`/api/core/module/${moduleId}/editGracePeriod`, gracePeriod)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updateModuleGracePeriod', status: { code: StatusCode.COMPLETE } });
            return StatusCode.COMPLETE;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updateModuleGracePeriod', status: { code: StatusCode.ERROR, error } });
            return StatusCode.ERROR;
        })
}

export const DeleteModuleGracePeriod = (moduleId, id) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'deleteModuleGracePeriod', status: { code: StatusCode.PENDING } })
    return InstanceClient.delete(`/api/core/module/${moduleId}/deleteGracePeriod/${id}`)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'deleteModuleGracePeriod', status: { code: StatusCode.COMPLETE } });
            return StatusCode.COMPLETE;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'deleteModuleGracePeriod', status: { code: StatusCode.ERROR, error } });
            return StatusCode.ERROR;
        })
}

export const getSelectableModuleTriggers = (id) => dispatch => {
    return send(dispatch, { prop: 'selectableTriggers', url: `/api/core/module/${id}/selectabletriggers`, dispatchPending: true });
};

export const resetModuleTriggers = () => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'selectableTriggers', status: { code: StatusCode.NONE } })
};

export const getModules = () => dispatch => {
    return send(dispatch, { prop: 'modules', url: `/api/core/module` });
}

export const getEventIds = (moduleId, instanceId) => dispatch => {
    return send(dispatch, { prop: 'eventIds', url: `/api/core/module/${moduleId}/instances/${instanceId}/events`, dispatchPending: true })
}

export const getModule = (id, dispatchPending) => dispatch => {
    return send(dispatch, { prop: 'module', url: `/api/core/module/${id}`, dispatchPending });
}

export const getMyModuleTypes = (dispatchPending) => dispatch => {
    return send(dispatch, { prop: 'myModuleTypes', url: `/api/core/module/myModuleTypes`, dispatchPending });
}

export const updateModule = (module) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updatedModule', status: { code: StatusCode.PENDING } })

    return InstanceClient.put(`/api/core/module/${module.id}`, module)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updatedModule', status: { code: StatusCode.COMPLETE } });
            return response;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updatedModule', status: { code: StatusCode.ERROR, error } });
            return null;
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updatedModule', status: { code: StatusCode.NONE } })
            }, 1500)
        });
}

export const createModule = (module) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createdModule', status: { code: StatusCode.PENDING } })
    return InstanceClient.post(`/api/core/module`, module)
        .then(response => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createdModule', status: { code: StatusCode.COMPLETE, data: response.data } });
            return response.data;
        })
        .catch(error => {
            dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createdModule', status: { code: StatusCode.ERROR, error } });
            return null;
        })
}

export const clearNewModule = () => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'createdModule', status: { code: StatusCode.NONE } })
}

export const getModuleTypes = () => dispatch => {
    return send(dispatch, { prop: 'moduleTypes', url: `/api/core/module/moduletypes`, dispatchPending: true });
}

export const getModuleStatus = (id, dispatchPending = true) => dispatch => {
    return send(dispatch, { prop: 'moduleStatus', url: `/api/core/module/${id}/status`, dispatchPending });
}

export const getModuleInstance = (moduleId, moduleInstanceId, dispatchPending = true) => dispatch => {
    return send(dispatch, { prop: 'moduleInstance', url: `/api/core/module/${moduleId}/instance/${moduleInstanceId}`, dispatchPending });
}

export const getModuleBasicInfo = (id) => dispatch => {
    const storeKey = store.getState().module.moduleBasicInfo.key;
    const key = id;
    if (key === storeKey) {
        return;
    }

    return InstanceClient.get(`/api/core/module/${id}/basicInfo`)
        .then(res =>
            dispatch({ type: GET_BASIC_INFO, moduleBasicInfo: { code: StatusCode.COMPLETE, data: res.data, key: key } }))
        .catch(err =>
            dispatch({ type: GET_BASIC_INFO, moduleBasicInfo: { code: StatusCode.ERROR, error: err.response.status } }));
}

export const resetModuleBasicInfo = () => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'moduleBasicInfo', status: { code: StatusCode.NONE } })
}

export const requestStart = (id) => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'startRequest', status: { code: StatusCode.PENDING } })
    return InstanceClient.post(`/api/core/module/${id}/requestStart`)
        .then(res => {
            dispatch({
                type: SET_MODULE_STATE_PROP, prop: 'startRequest', status: { code: StatusCode.COMPLETE }
            })
        })
        .catch(error => {
            return dispatch({ type: SET_MODULE_STATE_PROP, prop: 'startRequest', status: { code: StatusCode.ERROR, error } })
        })
        .finally(() => {
            setTimeout(() => {
                return dispatch({ type: SET_MODULE_STATE_PROP, prop: 'startRequest', status: { code: StatusCode.NONE } })
            }, 3000)
        })
}

export const getSystemNames = () => dispatch => {
    return send(dispatch, { prop: 'systemNames', url: `/api/core/module/systemNames`, dispatchPending: true });
}

export const resetUpdateModule = () => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'updatedModule', status: { code: StatusCode.NONE } });
}

export const resetGetModule = () => dispatch => {
    dispatch({ type: SET_MODULE_STATE_PROP, prop: 'module', status: { code: StatusCode.NONE } });
}

export const getInstances = (moduleId, filter = {}) => dispatch => {
    return InstanceClient.get(`/api/core/module/${moduleId}/instances`, filter)
        .then(response => {
            dispatch({
                type: GET_INSTANCE_PAGE,
                instances: {
                    code: StatusCode.COMPLETE,
                    data: response.data.values,
                    moduleId: moduleId,
                    currentPage: response.data.currentPage,
                    pageSize: response.data.pageSize,
                    totalPages: response.data.totalPages,
                    filter: filter
                }
            });
        })
        .catch(error => {
            return dispatch({ type: GET_INSTANCE_PAGE, instances: { code: StatusCode.ERROR, error } })
        });
}

export const getInstanceLogs = (moduleId, instanceId, filter = {}, update = false) => dispatch => {
    if (update) {
        dispatch({ type: GET_INSTANCELOGS_UPDATE });
    }
    return InstanceClient.get(`/api/core/module/${moduleId}/instances/${instanceId}/log`, filter)
        .then(res => {
            dispatch({
                type: GET_INSTANCELOGS,
                instanceLogs: {
                    code: StatusCode.COMPLETE,
                    data: res.data.values,
                    totalCount: res.data.totalCount,
                    pagination: {
                        currentPage: res.data.currentPage,
                        pageSize: res.data.pageSize,
                        totalPages: res.data.totalPages
                    }
                }
            });
        })
        .catch(error => {
            return dispatch({
                type: GET_INSTANCELOGS,
                instanceLogs: { code: StatusCode.ERROR, error: error }
            });
        });
}

export const GetObjectLogs = (params = {}) => dispatch => {
    const type = GET_OBJECT_LOGS;
    const name = "objectLogs";

    const storeKey = store.getState().module.objectLogs?.key;
    const key = params.objectId + '-' + params.objectSource + '-' + params.objectType;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: type,
        [name]: { code: StatusCode.PENDING }
    })

    return InstanceClient.get(`/api/core/module/moduleInstance/objectLog`, params)
        .then(res =>
            dispatch({
                type: type,
                [name]: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        )
        .catch(err =>
            dispatch({
                type: type,
                [name]: { code: StatusCode.ERROR, error: err }
            })
        )
}

export const downloadInstanceLogs = (moduleId, instanceId, filter = {}) => dispatch => {
    return InstanceClient.get(`/api/core/module/${moduleId}/instances/${instanceId}/log/download`, filter)
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            let fileName = `InstanceLog-${instanceId}.csv`;

            const contentDisposition = res.headers['content-disposition'];
            if (contentDisposition) {
                var matches = /filename=([^;]*);/.exec(contentDisposition);
                if (matches !== null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
}

const send = async (dispatch, config) => {
    const type = or_default(config.type, SET_MODULE_STATE_PROP);
    if (config.dispatchPending) {
        dispatch({ type, prop: config.prop, status: { code: StatusCode.PENDING } });
    }

    return InstanceClient.send({
        method: or_default(config.method, 'GET'),
        url: config.url,
        params: config.params,
        data: config.data
    }).then(response => {
        dispatch({ type, prop: config.prop, status: { code: StatusCode.COMPLETE, data: response.data } });
        return response.data;
    }, error => {
        dispatch({ type, prop: config.prop, status: { code: StatusCode.ERROR, error } });
        return null;
    });
}

const or_default = (value, def) => {
    if (value) {
        return value;
    } else {
        return def;
    }
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case SET_MODULE_STATE_PROP:
            return {
                ...state,
                [action.prop]: action.status,
            }
        case GET_INSTANCELOGS:
            return {
                ...state,
                instanceLogs: action.instanceLogs,
            };
        case GET_BASIC_INFO:
            return {
                ...state,
                moduleBasicInfo: action.moduleBasicInfo,
            };
        case GET_INSTANCELOGS_UPDATE:
            return {
                ...state,
                instanceLogs: { ...state.instanceLogs, code: StatusCode.PENDING }
            }
        case GET_INSTANCE_PAGE:
            return {
                ...state,
                instances: action.instances
            };
        case GET_OBJECT_LOGS:
            return {
                ...state,
                objectLogs: action.objectLogs
            }
        default:
            return state;
    }
}
