import { Action, Dispatch, Reducer } from 'redux';
import { PageResult } from '../models/CoreModels';
import { CoreTracking } from '../models/CoreTracking';
import { CoreUser, CoreUserRelation, CoreUserSchoolUnitRelations, CoreUserActivityListing, CoreUserStudentGroupListing, CoreUserListing } from '../models/CoreUser';
import { SearchPageRequest, UserSearchPageRequest } from '../models/Requests';
import { CacheRequestState, EmptyRequestState, ExtendedRequestState, RequestState } from '../models/RequestState';
import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import store from './store';

enum UserActionType {
    LIST_USERS = 'LIST_USERS',
    LIST_USERS_FILTER = 'LIST_USERS_FILTER',
    LIST_USERS_UPDATE = 'LIST_USERS_UPDATE',
    GET_USER = 'GET_USER',
    GET_USER_RELATION = 'GET_USER_RELATION',
    GET_USER_TRACKINGS = 'GET_USER_TRACKINGS',
    GET_TEACHER_GROUPS = 'GET_USER_GROUPS',
    GET_STUDENT_GROUPS = 'GET_STUDENT_GROUPS',
    GET_STUDENT_ACTIVITIES = 'GET_STUDENT_ACTIVITIES',
    GET_TEACHER_ACTIVITIES = 'GET_TEACHER_ACTIVITIES',
    GET_USER_SCHOOLUNITS = 'GET_USER_SCHOOLUNITS',
    PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
}

export type UserGroupsRequestState = ExtendedRequestState<PageResult<CoreUserStudentGroupListing>, { filter: Partial<SearchPageRequest> }>
export type UserActivitiesRequestState = ExtendedRequestState<PageResult<CoreUserActivityListing>, { filter: Partial<SearchPageRequest> }>
export type UserSchoolUnitsRequestState = ExtendedRequestState<CoreUserSchoolUnitRelations, { filter: Partial<SearchPageRequest> }>

export interface UserState {
    users: CacheRequestState<PageResult<CoreUserListing>>
    user: RequestState<CoreUser>
    userTrackings: RequestState<CoreTracking[]>
    userSchoolUnits: UserSchoolUnitsRequestState
    userRelation: RequestState<CoreUserRelation[]>
    teacherGroups: UserGroupsRequestState
    studentGroups: UserGroupsRequestState
    teacherActivities: UserActivitiesRequestState
    studentActivities: UserActivitiesRequestState
    primarySchool: EmptyRequestState
    filter: Partial<UserSearchPageRequest>
}

interface User_ListUsersBase extends Action<UserActionType> {
    type: UserActionType.LIST_USERS
    users: RequestState<PageResult<CoreUserListing>>,
}

interface User_ListUsersFilter extends Action<UserActionType> {
    type: UserActionType.LIST_USERS_FILTER
    filter: {}
}

interface User_ListUsersUpdate extends Action<UserActionType> {
    type: UserActionType.LIST_USERS_UPDATE
}

type User_ListUsers = User_ListUsersBase | User_ListUsersFilter | User_ListUsersUpdate;

interface User_GetUser extends Action<UserActionType> {
    type: UserActionType.GET_USER
    user: RequestState<CoreUser>
}

interface User_GetUserRelation extends Action<UserActionType> {
    type: UserActionType.GET_USER_RELATION
    userRelation: RequestState<CoreUserRelation[]>
}

interface User_GetUserTrackings extends Action<UserActionType> {
    type: UserActionType.GET_USER_TRACKINGS
    userTrackings: RequestState<CoreTracking[]>
}

interface User_GetTeacherGroups extends Action<UserActionType> {
    type: UserActionType.GET_TEACHER_GROUPS
    teacherGroups: UserGroupsRequestState
}

interface User_GetStudentGroups extends Action<UserActionType> {
    type: UserActionType.GET_STUDENT_GROUPS
    studentGroups: UserGroupsRequestState
}

interface User_GetTeacherActivities extends Action<UserActionType> {
    type: UserActionType.GET_TEACHER_ACTIVITIES
    teacherActivities: UserActivitiesRequestState
}

interface User_GetStudentActivities extends Action<UserActionType> {
    type: UserActionType.GET_STUDENT_ACTIVITIES
    studentActivities: UserActivitiesRequestState
}

interface User_GetUserSchoolUnits extends Action<UserActionType> {
    type: UserActionType.GET_USER_SCHOOLUNITS
    userSchoolUnits: UserSchoolUnitsRequestState
}

interface User_GetPrimarySchool extends Action<UserActionType> {
    type: UserActionType.PRIMARY_SCHOOL
    primarySchool: EmptyRequestState
}

type UserAction = User_ListUsers
    | User_GetUser
    | User_GetUserRelation
    | User_GetUserTrackings
    | User_GetTeacherGroups
    | User_GetStudentGroups
    | User_GetTeacherActivities
    | User_GetStudentActivities
    | User_GetUserSchoolUnits
    | User_GetPrimarySchool

export const GetUsers = (filter: Partial<UserSearchPageRequest> = {}, update: boolean = false) => (dispatch: Dispatch<User_ListUsers>) => {
    dispatch({ type: UserActionType.LIST_USERS_FILTER, filter: filter })
    if (update) {
        dispatch({ type: UserActionType.LIST_USERS_UPDATE })
    }

    const url = '/api/core/user'
    InstanceClient.get<PageResult<CoreUserListing>>(url, filter)
        .then(res =>
            dispatch({
                type: UserActionType.LIST_USERS,
                users: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                }
            })
        )
        .catch(err =>
            dispatch({
                type: UserActionType.LIST_USERS,
                users: { code: StatusCode.ERROR, error: err }
            })
        );
};

export const GetUser = (id: string) => (dispatch: Dispatch<User_GetUser>) => {
    const storeKey = store.getState().users.user?.key;
    const key = id;

    if (key === storeKey) {
        return;
    }

    dispatch({ type: UserActionType.GET_USER, user: { code: StatusCode.PENDING } })

    const url = '/api/core/user/' + id
    InstanceClient.get<CoreUser>(url)
        .then(res =>
            dispatch({
                type: UserActionType.GET_USER,
                user: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        )
        .catch(err =>
            dispatch({
                type: UserActionType.GET_USER,
                user: {
                    code: StatusCode.ERROR, error: err
                }
            })
        );
};

export const GetUserTrackings = (id: string, force: boolean = false) => (dispatch: Dispatch<User_GetUserTrackings>) => {
    const storeKey = store.getState().users.userTrackings?.key;
    const key = id;

    if (key === storeKey && !force) {
        return;
    }

    const type = UserActionType.GET_USER_TRACKINGS;

    dispatch({ type: type, userTrackings: { code: StatusCode.PENDING } })

    const url = `/api/core/user/${id}/trackings`;
    InstanceClient.get<CoreTracking[]>(url)
        .then(res =>
            dispatch({
                type: type,
                userTrackings: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        )
        .catch(err =>
            dispatch({
                type: type,
                userTrackings: { code: StatusCode.ERROR, error: err }
            })
        );
};

export const GetUserSchoolUnits = (id: string, refresh: boolean = false, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<User_GetUserSchoolUnits>) => {
    const storeKey = store.getState().users.userSchoolUnits?.key;
    const key = id + '_' + filter.onlyActive;

    if (refresh === false &&
        key === storeKey) {
        return;
    }

    const type = UserActionType.GET_USER_SCHOOLUNITS;

    dispatch({ type: type, userSchoolUnits: { code: StatusCode.PENDING, filter }})

    const url = `/api/core/user/${id}/schoolUnits`;
    InstanceClient.get<CoreUserSchoolUnitRelations>(url, filter)
        .then(res =>
            dispatch({
                type: type,
                userSchoolUnits: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    key: key, 
                    filter
                }
            })
        )
        .catch(err =>
            dispatch({
                type: type,
                userSchoolUnits: {
                    code: StatusCode.ERROR,
                    error: err,
                    filter
                }
            })
        );
};

export const SetUserPrimarySchool = (userId: string, schoolId: string) => (dispatch: Dispatch<User_GetPrimarySchool>) => {
    dispatch({ type: UserActionType.PRIMARY_SCHOOL, primarySchool: { code: StatusCode.PENDING } });
    
    InstanceClient.post(`/api/core/user/${userId}/schoolUnits/primary/${schoolId}`)
        .then(() => dispatch({ type: UserActionType.PRIMARY_SCHOOL, primarySchool: { code: StatusCode.COMPLETE } }))
        .catch(err => {
            dispatch({ type: UserActionType.PRIMARY_SCHOOL, primarySchool: { code: StatusCode.ERROR, error: err } })
        });
};

export const DeleteUserPrimarySchool = (userId: string) => (dispatch: Dispatch<User_GetPrimarySchool>) => {
    dispatch({ type: UserActionType.PRIMARY_SCHOOL, primarySchool: { code: StatusCode.PENDING } });
    InstanceClient.delete(`/api/core/user/${userId}/schoolUnits/primary`)
        .then(() => dispatch({ type: UserActionType.PRIMARY_SCHOOL, primarySchool: { code: StatusCode.COMPLETE } }))
        .catch(err => {
            dispatch({ type: UserActionType.PRIMARY_SCHOOL, primarySchool: { code: StatusCode.ERROR, error: err } })
        });
}

export const ResetPrimarySchoolSaveStatus = () => (dispatch: Dispatch<User_GetPrimarySchool>) => {
    dispatch({ type: UserActionType.PRIMARY_SCHOOL, primarySchool: { code: StatusCode.NONE } });
}

export const GetUserRelations = (id: string) => (dispatch: Dispatch<User_GetUserRelation>) => {
    const storeKey = store.getState().users.userRelation?.key;
    const key = id;
    if (key === storeKey) {
        return
    }

    dispatch({ type: UserActionType.GET_USER_RELATION, userRelation: { code: StatusCode.PENDING } });

    const url = `/api/core/user/${id}/relations`;
    InstanceClient.get<CoreUserRelation[]>(url)
        .then(res => 
            dispatch({
                type: UserActionType.GET_USER_RELATION,
                userRelation: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    key: key,
                }
            })
        )
        .catch(err =>
            dispatch({
                type: UserActionType.GET_USER_RELATION,
                userRelation: { code: StatusCode.ERROR, error: err }
            })
        );
};

export const GetTeacherGroups = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<User_GetTeacherGroups>) => {
    const storeKey = store.getState().users.teacherGroups?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return
    }
    dispatch({ type: UserActionType.GET_TEACHER_GROUPS, teacherGroups: { code: StatusCode.PENDING, filter } });

    const url = `/api/core/user/${id}/groups/teacher`;
    InstanceClient.get<PageResult<CoreUserStudentGroupListing>>(url, filter)
        .then(res =>
            dispatch({
                type: UserActionType.GET_TEACHER_GROUPS,
                teacherGroups: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                }
            })
        )
        .catch(error =>
            dispatch({
                type: UserActionType.GET_TEACHER_GROUPS,
                teacherGroups: { code: StatusCode.ERROR, error, filter }
            })
        );
};

export const GetStudentGroups = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<User_GetStudentGroups>) => {
    const storeKey = store.getState().users.studentGroups?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return
    }
    dispatch({ type: UserActionType.GET_STUDENT_GROUPS, studentGroups: { code: StatusCode.PENDING, filter } });

    const url = `/api/core/user/${id}/groups/student`;
    InstanceClient.get<PageResult<CoreUserStudentGroupListing>>(url, filter)
        .then(res =>
            dispatch({
                type: UserActionType.GET_STUDENT_GROUPS,
                studentGroups: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                }
            })
        )
        .catch(error =>
            dispatch({
                type: UserActionType.GET_STUDENT_GROUPS,
                studentGroups: { code: StatusCode.ERROR, error, filter }
            })
        );
};

export const GetTeacherActivities = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<User_GetTeacherActivities>) => {
    const storeKey = store.getState().users.teacherActivities?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return
    }
    dispatch({ type: UserActionType.GET_TEACHER_ACTIVITIES, teacherActivities: { code: StatusCode.PENDING, filter } });

    const url = `/api/core/user/${id}/activities/teacher`;
    InstanceClient.get<PageResult<CoreUserActivityListing>>(url, filter)
        .then(res =>
            dispatch({
                type: UserActionType.GET_TEACHER_ACTIVITIES,
                teacherActivities: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                }
            })
        )
        .catch(error =>
            dispatch({
                type: UserActionType.GET_TEACHER_ACTIVITIES,
                teacherActivities: { code: StatusCode.ERROR, error, filter }
            })
        );
};

export const GetStudentActivities = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<User_GetStudentActivities>) => {
    const storeKey = store.getState().users.studentActivities?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return
    }
    dispatch({ type: UserActionType.GET_STUDENT_ACTIVITIES, studentActivities: { code: StatusCode.PENDING, filter } });

    const url = `/api/core/user/${id}/activities/student`;
    InstanceClient.get<PageResult<CoreUserActivityListing>>(url, filter)
        .then(res =>
            dispatch({
                type: UserActionType.GET_STUDENT_ACTIVITIES,
                studentActivities: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                }
            })
        )
        .catch(error =>
            dispatch({
                type: UserActionType.GET_STUDENT_ACTIVITIES,
                studentActivities: { code: StatusCode.ERROR, error, filter }
            })
        );
};

const initialState: UserState = {
    users: { code: StatusCode.PENDING },
    user: { code: StatusCode.NONE },
    userTrackings: { code: StatusCode.NONE },
    userRelation: { code: StatusCode.PENDING },
    userSchoolUnits: { code: StatusCode.PENDING, filter: {} },
    teacherGroups: { code: StatusCode.PENDING, filter: {} },
    studentGroups: { code: StatusCode.PENDING, filter: {} },
    teacherActivities: { code: StatusCode.PENDING, filter: {} },
    studentActivities: { code: StatusCode.PENDING, filter: {} },
    filter: {},
    primarySchool: { code: StatusCode.NONE },
}

export const reducer: Reducer<UserState, UserAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case UserActionType.LIST_USERS:
            return {
                ...state,
                users: action.users,
            }
        case UserActionType.LIST_USERS_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        case UserActionType.LIST_USERS_UPDATE:
            return {
                ...state,
                users: { ...state.users, code: StatusCode.PENDING },
            }
        case UserActionType.GET_USER:
            return {
                ...state,
                user: action.user,
            };
        case UserActionType.GET_USER_RELATION:
            return {
                ...state,
                userRelation: action.userRelation,
            };
        case UserActionType.GET_USER_TRACKINGS:
            return {
                ...state,
                userTrackings: action.userTrackings,
            };
        case UserActionType.GET_USER_SCHOOLUNITS:
            return {
                ...state,
                userSchoolUnits: action.userSchoolUnits,
            };
        case UserActionType.GET_TEACHER_GROUPS:
            return {
                ...state,
                teacherGroups: action.teacherGroups,
            };
        case UserActionType.GET_STUDENT_GROUPS:
            return {
                ...state,
                studentGroups: action.studentGroups,
            };
        case UserActionType.GET_TEACHER_ACTIVITIES:
            return {
                ...state,
                teacherActivities: action.teacherActivities,
            };
        case UserActionType.GET_STUDENT_ACTIVITIES:
            return {
                ...state,
                studentActivities: action.studentActivities,
            };
        case UserActionType.PRIMARY_SCHOOL:
            return {
                ...state,
                primarySchool: action.primarySchool,
            };
        default:
            return state;
    }
};
