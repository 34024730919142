import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableHorizontal, { InfoObject } from '../misc/TableHorizontal';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import { listAccountPasswords, PasswordAdminState, exportPasswordsFilter, exportPasswordsSelected } from '../../store/PasswordAdminAction'
import { clearClassGroups, FilterState, getClassGroups, getSchoolUnits } from '../../store/FilterAction';
import { CacheRequestState } from '../../models/RequestState';
import { CoreDisplayableRef, PageResult } from '../../models/CoreModels';
import { CorePasswordAdminAccount, CorePasswordAdminAccountRole } from '../../models/CorePasswordAdminModels';
import StatusCode from '../../util/StatusCode';
import { useSearchParams } from '../../../node_modules/react-router-dom/dist/index';
import RequestParser from '../../util/RequestParser';
import AuthorizedLink from '../auth/AuthorizedLink';
import { FlowSyncAccessPolicy, isAuthorized } from '../../models/FlowSyncAccessPolicy';
import { Col, Row, Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from '../misc/Search';
import { debounce } from 'lodash';
import Toggle from '../misc/Toggle';
import { formatDate } from '../../util/Helper';
import SearchableSelectInput from '../misc/SearchableSelectInput';
import { MeState } from '../../store/MeAction';
import ModalComponent from '../misc/ModalComponent';

enum ExportType {
    NONE = 0,
    FILTER = 1,
    SELECTED = 2,
}

interface LayoutProps extends PropsWithChildren {
    navSection?: string,
}

interface PasswordDistributionStore {
    passwordAdmin: PasswordAdminState
    filter: FilterState
    me: MeState
}

const PasswordDistribution: FC<LayoutProps> = props => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const passwordAdminAccounts = useSelector<PasswordDistributionStore, CacheRequestState<PageResult<CorePasswordAdminAccount>>>(x => x.passwordAdmin.passwordAccounts);
    const schoolUnits = useSelector<PasswordDistributionStore, CoreDisplayableRef[]>(x => x.filter.schoolUnits);
    const classGroups = useSelector<PasswordDistributionStore, CoreDisplayableRef[]>(x => x.filter.classGroups);
    const authorizedAccessPolicies = useSelector<PasswordDistributionStore, FlowSyncAccessPolicy[]>(state => state.me.me?.authorizedAccessPolicies ?? []);
    
    var filter = useMemo(() => RequestParser.parsePasswordAdminAccountRequest(searchParams), [searchParams]);
    const [search, setSearch] = useState(filter.search ?? '');
    const [selectedPaas, setSelectedPaas] = useState<{ [key: string]: boolean }>({});
    const [exportType, setExportType] = useState<ExportType>(ExportType.NONE);
    const [exportingPasswords, setExportingPasswords] = useState<boolean>(false)
     
    useEffect(() => {
        getSchoolUnits()(dispatch);
    }, [dispatch])

    useEffect(() => {
        if (filter.schoolUnitId) {
            getClassGroups(filter.schoolUnitId)(dispatch)
        }        
        else {
            clearClassGroups()(dispatch);
        }
    }, [dispatch, filter.schoolUnitId])

    useEffect(() => {
        listAccountPasswords(filter)(dispatch);
        setSelectedPaas({})
    }, [dispatch, filter])

    const clearFilter = useCallback(() => {
        setSearch('');
        setSearchParams([]);
    }, [setSearch, setSearchParams]);

    const updateFilter = useCallback((name: string, value?: number | string | boolean | null) => {
        if (name === 'schoolUnitId') {
            searchParams.delete('classGroupId')
        }

        if (name !== 'page' && searchParams.has('page')) {
            searchParams.delete('page');
        } 
        
        if (name === 'showExported' && value === false) {
            searchParams.delete(name);
        } else if (value !== undefined && value !== '' && value !== null) {
            if (typeof (value) === 'string') {
                searchParams.set(name, value);
            } else {
                searchParams.set(name, value.toString());
            }
        } else {
            searchParams.delete(name);
        }

        setSearchParams(searchParams);
    }, [searchParams, setSearchParams])

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    };

    const toggleSelected = (id: string) => {
        if (selectedPaas[id]) {
            selectedPaas[id] = false;
        } else {
            selectedPaas[id] = true;
        }
        setSelectedPaas({...selectedPaas});
    }

    const exportPasswords = async () => {
        setExportingPasswords(true)

        switch (exportType) {
            case ExportType.FILTER: {
                await exportPasswordsFilter(filter);
                break;
            }
            case ExportType.SELECTED: {
                var ids = Object.keys(selectedPaas)
                    .filter(k => selectedPaas[k]); // only export those with true value
                await exportPasswordsSelected(ids);
                break;
            }
            default:
                break;
        }

        listAccountPasswords(filter)(dispatch);
        setSelectedPaas({});
        setExportingPasswords(false)
        setExportType(ExportType.NONE);
    }

    const hasResults = () => {
        if (passwordAdminAccounts.code === StatusCode.COMPLETE || passwordAdminAccounts.code === StatusCode.PENDING) {
            if (passwordAdminAccounts.data) {
                return passwordAdminAccounts.data.totalCount > 0;
            }
        }

        return false;
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300)
    , [updateFilter]);

    const schoolUnitFilterItems = useMemo(() => {
        return schoolUnits.map(x => ({ value: x.id, label: x.title ?? "" }));
    }, [schoolUnits])

    const classGroupFilterItems = useMemo(() => {
        return classGroups.map(x => ({ value: x.id, label: x.title ?? "" }));
    }, [classGroups])

    var canExportExported = isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.EXPORT_EXPORTED_PASSWORD);
    var canExport = isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.EXPORT_UNEXPORTED_PASSWORD) || canExportExported;
    var selectedExportDisabled = !canExport || !Object.values(selectedPaas).some(x => x);
    var filterExportDisabled = !canExport || !hasResults() || (filter.showExported && !canExportExported);

    let tableContent: InfoObject[] = [];
    let resultCount = 0;
    if (passwordAdminAccounts.code === StatusCode.COMPLETE || passwordAdminAccounts.code === StatusCode.PENDING) {
        resultCount = passwordAdminAccounts.data?.totalCount ?? 0;
        tableContent = passwordAdminAccounts.data?.values.map(x => {
            let role = '-';
            if (x.role === CorePasswordAdminAccountRole.STAFF) {
                role = 'Lärare';
            }
            else if (x.role === CorePasswordAdminAccountRole.STUDENT) {
                role = 'Elev';
            }

            const disableCheckbox = x.exported && !canExportExported;

            return {
                id: x.userTrackingId,
                data: [
                    { value: <Input type="checkbox" id={x.userTrackingId} checked={selectedPaas[x.userTrackingId]} onChange={() => toggleSelected(x.userTrackingId)} disabled={disableCheckbox} /> },
                    { value: <AuthorizedLink to={`/user/${x.user.id}`} accessPolicy={FlowSyncAccessPolicy.READ}>{x.user.title}</AuthorizedLink> },
                    { value: x.schoolUnit ? <AuthorizedLink to={`/schoolunit/${x.schoolUnit.id}`} accessPolicy={FlowSyncAccessPolicy.READ}>{x.schoolUnit.title}</AuthorizedLink> : '-' },
                    { value: x.classGroup ? <AuthorizedLink to={`/group/${x.classGroup.id}`} accessPolicy={FlowSyncAccessPolicy.READ}>{x.classGroup.title}</AuthorizedLink> : '-' },
                    { value: role },
                    { value: x.username ?? '-' },
                    { value: x.exported ? `Hämtad ${formatDate(x.exportedAt, false)} av ${x.exportedBy}` : 'Ej hämtad' },
                ]
            }
        }) ?? [];
    }

    return (
        <div className="container">
            <ModalComponent
                isOpen={exportType !== ExportType.NONE}
                toggleModal={() => setExportType(ExportType.NONE)}
                header="Hämta lösenord"
                update={exportPasswords}
                confirm="Bekräfta"
                cancel="Stäng"
                saveStatus={exportingPasswords ? StatusCode.PENDING : StatusCode.NONE}
            >
                <p>Hämtar {exportType === ExportType.SELECTED ? Object.values(selectedPaas).filter(x => x).length : resultCount} lösenord. De lösenord du hämtar kommer att markeras som hämtade av dig.</p>
                {!canExportExported && <p><FontAwesomeIcon title="WARNING" color="#fc1" icon={['fas', 'triangle-exclamation']} /> Du kommer inte att kunna hämta dessa lösenord igen.</p>}
            </ModalComponent>
            <div className="header--fixed large-filter">
                <h1>Lösenordsdistribution</h1>
                <div>
                    <Row>
                        <Col sm="3">
                            <Search value={search} onChange={onSearch} placeholder="Sök på namn" />
                        </Col>
                        <Col sm="2">
                            <SearchableSelectInput
                                items={schoolUnitFilterItems}
                                onChange={(name, value) => updateFilter(name, value)}
                                name='schoolUnitId'
                                selected={filter.schoolUnitId}
                                defaultText='Välj skola'
                            />
                        </Col>
                        <Col sm="2">
                            <SearchableSelectInput
                                items={classGroupFilterItems}
                                onChange={(name, value) => updateFilter(name, value)}
                                name='classGroupId'
                                selected={filter.classGroupId}
                                disabled={classGroupFilterItems.length === 0}
                                defaultText='Välj klass'
                            />
                        </Col>
                        <Col sm="2">
                            <SearchableSelectInput
                                items={[{ label: 'Lärare', value: CorePasswordAdminAccountRole.STAFF }, { label: 'Elev', value: CorePasswordAdminAccountRole.STUDENT }]}
                                onChange={(name, value) => updateFilter(name, value)}
                                name='role'
                                selected={filter.role}
                                defaultText='Välj roll'
                            />
                        </Col>
                        <Col sm="3">
                            <Toggle
                                id='showExported'
                                title='Visa hämtade'
                                value={filter.showExported ?? false}
                                update={(showExported) => updateFilter('showExported', showExported)}
                                disabled={passwordAdminAccounts.code === StatusCode.PENDING}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className="toggle">
                            {(filter.showExported && !canExportExported) && <p className="red" style={{margin: "0.4em"} }>Du saknar behörighet att hämta redan hämtade lösenord</p> }
                            <Button color="primary" className="start-request" onClick={() => setExportType(ExportType.FILTER)} disabled={filterExportDisabled}>Hämta nuvarande vy</Button>
                            <Button color="primary" className="start-request" onClick={() => setExportType(ExportType.SELECTED)} disabled={selectedExportDisabled}>Hämta endast markerade lösenord</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="body--scroll padding-40">
                <TableHorizontal
                    header={[
                        { value: '' },
                        { value: 'Namn' },
                        { value: 'Skola' },
                        { value: 'Klass' },
                        { value: 'Roll' },
                        { value: 'Användarnamn' },
                        { value: 'Lösenordstatus' }
                    ]}
                    info={tableContent}
                    error={passwordAdminAccounts.code === StatusCode.ERROR}
                    searching={passwordAdminAccounts.code === StatusCode.PENDING}
                    displayName="Användarkonton"
                    noResMsg="Inga användarkonton hittades"
                />
            </div>
            <FilterBottomComponent
                requestState={passwordAdminAccounts}
                clearFilter={() => clearFilter()}
                updateFilter={(value) => updateFilter('page', value)}
            />
        </div>
    );
};

export default PasswordDistribution;